import Vue from 'vue';
import Router from 'vue-router';
import HomePage from './components/HomePage.vue';
import BlogDetail from './components/BlogDetail.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage
    },
    {
      path: '/:slug', // Dynamic route for slug
      name: 'BlogDetail',
      component: BlogDetail,
      props: true // Pass route params as props
    }
  ]
});
