<template>
    <header>
            <!-- back to top -->
            <a id="button"></a>


            <!-- back to top -->
            <div class="rts_header__switch header__switch--wrapper">
                <label class="header__switch__label" for="headerSwitchCheckbox">
                <input class="header__switch__input" type="checkbox" name="headerSwitchCheckbox" id="headerSwitchCheckbox">
                <span class="header__switch__tooltip header__switch__tooltip--dark">
                        <svg class="sun-and-moon" aria-hidden="true" width="24" height="24" viewBox="0 0 24 24">
                            <mask class="moon" id="moon-mask">
                                <rect x="0" y="0" width="100%" height="100%" fill="white"></rect>
                                <circle cx="24" cy="10" r="6" fill="black"></circle>
                            </mask>
                            <circle class="sun" cx="12" cy="12" r="6" mask="url(#moon-mask)" fill="currentColor"></circle>
                            <g class="sun-beams" stroke="currentColor">
                                <line x1="12" y1="1" x2="12" y2="3"></line>
                                <line x1="12" y1="21" x2="12" y2="23"></line>
                                <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                                <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                                <line x1="1" y1="12" x2="3" y2="12"></line>
                                <line x1="21" y1="12" x2="23" y2="12"></line>
                                <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                                <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                            </g>
                        </svg>
                </span>
                <span class="header__switch__tooltip header__switch__tooltip--light">
                    <i class="fas fa-moon"></i>
                </span>
            </label>
           </div>

            <!-- top info -->
            <section id="top_info">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-3 col-3 col-lg-center col-md-center d-flex justify-content-between align-items-center text-center">
                            <div class="social-icons">
                                <a href="#"><i class="fab fa-facebook"></i></a>
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-youtube"></i></a>
                                <a href="#"><i class="fab fa-pinterest"></i></a>
                                <a href="#"><i class="fab fa-medium"></i></a>
                            </div>
                        </div>
  
                        <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="logo align-items-center text-center justify-content-center">
                                <a href="#">
                                    <img src="/assets/images/favi.svg" alt="Echoo Logo">
                                </a>
  
                            </div>
  
                        </div>
  
                        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                            <div class="subscribe text-right justify-content-end float-end">
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 19 16"
                                        fill="none">
                                        <path
                                            d="M14.2221 5.48096L10.6675 8.37139C9.99586 8.90419 9.05096 8.90419 8.37937 8.37139L4.79474 5.48096"
                                            stroke="#ffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                        </path>
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M13.4271 15.2C15.8602 15.2067 17.5 13.2077 17.5 10.7507V5.25606C17.5 2.79911 15.8602 0.800049 13.4271 0.800049H5.57291C3.13983 0.800049 1.5 2.79911 1.5 5.25606V10.7507C1.5 13.2077 3.13983 15.2067 5.57291 15.2H13.4271Z"
                                            stroke="#ffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                        </path>
                                    </svg>
                                    &nbsp;
                                    Subscribe</a>
                            </div>
                        </div>
  
                    </div>
                </div>
            </section>
  
            <!-- navbar -->
            <nav>
                <div class="wrapper">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-2 col-md-2 col-sm-2 col-2">
                                <header>
                                    <div class="header">
                                        <div class="navbar-right nav_menu">
                    
                                            <span class="" style=" " onclick="openNav()"> <svg xmlns="http://www.w3.org/2000/svg"
                                                    width="20" height="19" viewBox="0 0 20 19" fill="none">
                                                    <path
                                                        d="M0.526001 0.953369H20V3.11715H0.526001V0.953369ZM7.01733 8.52659H20V10.6904H7.01733V8.52659ZM0.526001 16.0998H20V18.2636H0.526001V16.0998Z"
                                                        fill="#5E5E5E"></path>
                                                </svg> </span>
                                        </div>
                                    </div>
                                </header>
                            </div>
                        <!-- <input type="radio" name="slider" id="menu-btn">
                        <input type="radio" name="slider" id="close-btn"> -->
                        <div class="col-lg-8 col-md-8 col-sm-8 col-8">
                            <div class="text-center">
                                <ul class="nav-links">
                                    <label for="close-btn" class="btn close-btn"><i class="fas fa-times"></i></label>
                                    <li><router-link to="/">Home</router-link></li>
                                    <li><a href="#">About</a></li>
                                    <li><a href="#">Devops</a></li>
                                    <li><a href="#">Gadget</a></li>
                                    <li><a href="#">Chatgpt</a></li>
                                    <li>
                                    <a href="#" class="desktop-item">Post <i class="fa fa-chevron-down" style="font-size: 12px;"></i></a>
                                    <div class="mega-box">
                                        <div class="content">
                                        <div class="row">
                                            <router-link to="/blog">
                                                <div class="card">
                                                    <img src="/assets/images/chatbot-application-370x300.webp" class="card-img-top" alt="..." />
                                                    <h5 class="pt-3">How ChatGPT Can Assist In Creating More Effective Content</h5>
                                                </div>
                                            </router-link>
                                        </div>
                                        <div class="row">
                                            <router-link to="/blog">
                                                <div class="card">
                                                <img src="/assets/images/blockchain-370x300.jpg" class="card-img-top" alt="..." />
                                                    <h5 class="pt-3">A Journey Through The DevOps Pipeline</h5>
                                                </div>
                                            </router-link>
                                        </div>
                                        <div class="row">
                                            <router-link to="/blog">
                                                <div class="card">
                                                <img src="/assets/images/chat-370x300.webp" class="card-img-top" alt="..." />
                                                    <h5 class="pt-3">Greening The Environmental Impact of ChatGpt</h5>
                                                </div>
                                            </router-link>
                                        </div>
                                        <div class="row">
                                            
                                            <router-link to="/blog">
                                                <div class="card">
                                                <img src="/assets/images/iphone-battery-370x300.jpg" class="card-img-top" alt="..." />
                                                    <h5 class="pt-3">IPhone Tips For Prolonging Battery Life</h5>
                                                </div>
                                            </router-link>
                                        </div>
                                        </div>
                                    </div>
                                    </li>
                                    <li><a href="#">Contact</a></li>
                                </ul>
                            </div>
  
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-2 text-right justify-content-end float-end">
                            <!-- <label for="menu-btn" class="btn menu-btn"><i class="fas fa-bars"></i></label> -->
                            <div class="search-icon text-right justify-content-end float-end">
                            <i class="fas fa-search"></i>
                            </div>
                        
                        </div>
                        </div>
                    </div>
  
  
                </div>
            </nav>
  
            <!-- Full Screen Menu -->
            <div id="fullScreenMenu" class="sidenav">
                <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                
                <div class="row">
                    <div class="col-lg-12 text-left mx-4">
                        <img src="/assets/images/favi.svg" alt="Echoo Logo"/>
                    </div>
                </div>
  
                <div class="app-container">
                    <div class="menu">
                        <a href="#" class="menu-item">
                            <img src="/assets/images/bitcoin-new-80x80.webp" alt="Bitcoin">
                            <div>
                                <h3>Bitcoin Regulations – Navigating L</h3>
                                <p><i class="fa fa-user"></i>&nbsp; by Alexis</p>
                            </div>
                        </a>
                        <a href="#" class="menu-item">
                            <img src="/assets/images/b1-2048x1536-1-80x80.webp" alt="DevOps">
                            <div>
                                <h3>Implementing DevOps in Large Enterprises</h3>
                                <p><i class="fa fa-user"></i>&nbsp; by Alexis</p>
                            </div>
                        </a>
                        <a href="#" class="menu-item">
                            <img src="/assets/images/chatbot-application-80x80.webp" alt="ChatGPT">
                            <div>
                                <h3>How ChatGPT can assist in creating more</h3>
                                <p><i class="fa fa-user"></i>&nbsp; by Alexis</p>
                            </div>
                        </a>
                        <a href="#" class="menu-item">
                            <img src="/assets/images/4-80x80.webp" alt="Happiness">
                            <div>
                                <h3>World Happiness Report 2023: What'</h3>
                                <p><i class="fa fa-user"></i>&nbsp; by Alexis</p>
                            </div>
                        </a>
                    </div>
                    <div class="social-media">
                        <a href="#" class="social-icon facebook"><i class="fab fa-facebook"></i></a>
                        <a href="#" class="social-icon twitter"><i class="fab fa-twitter"></i></a>
                        <a href="#" class="social-icon youtube"><i class="fab fa-youtube"></i></a>
                        <a href="#" class="social-icon linkedin"><i class="fab fa-linkedin"></i></a>
                    </div>
                </div>
  
            </div> 
  
            <!-- Medium Screen Menu -->
            <div id="mediumScreenMenu" class="sidenav">
                <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                <div class="content mt-3">
                    <a href="#">Home</a>
                    <div class="dropdown">
                        <button class="dropbtn btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            Pages
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li><a class="dropdown-item" href="#">About</a></li>
                            <li><a class="dropdown-item" href="#">Team</a></li>
                            <li><a class="dropdown-item" href="#">Author</a></li>
                            <li><a class="dropdown-item" href="#">Error</a></li>
                        </ul>
                    </div>
                    
                    <a href="#">Devops</a>
                    <a href="#">Gadget</a>
                    <a href="#">Chatgpt</a>
                    <div class="dropdownn">
                        <div class="dropbtn">Post <i class="fa fa-chevron-down" style="font-size: 12px;"></i></div>
                        <div class="dropdown-content">
                            <a href="#">How ChatGPT can assist in creating more effective content</a>
                            <a href="#">A Journey Through the DevOps Pipeline</a>
                            <a href="#">Greening The Environmental Impact of ChatGpt</a>
                            <a href="#">iPhone Tips for Prolonging Battery Life</a>
                        </div>
                    </div>
                    <a href="#">Contact</a>
                </div>
  
            </div>
  
    </header>
  </template>
  <script>
        export default {
            name: 'SiteHeader',
            methods: {
            openNav() {
                document.getElementById("fullScreenMenu").style.width = "100%";
            },
            closeNav() {
                document.getElementById("fullScreenMenu").style.width = "0";
            },
            scrollToTop() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            }
        };
  </script>
  
    