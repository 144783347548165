<template>
            <section id="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-4 col-md-3 col-sm-6 touch">
                        <h4>Get In Touch /</h4>
                        <p> <i class="far fa-map"></i> &nbsp; 255 Sheet, New Avanew, NY</p>
                        <p><i class="fas fa-phone-alt"></i> &nbsp; (00) 236 123 456 88</p>
                        <p><i class="far fa-envelope"></i> &nbsp; Info@Demomail.Com</p>
                        <div class="social-icons">
                            <a href="https://www.facebook.com" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            <a href="https://www.twitter.com" target="_blank"><i class="fab fa-twitter"></i></a>
                            <a href="https://www.linkedin.com" target="_blank"><i class="fab fa-linkedin-in"></i></a>
                            <a href="https://www.instagram.com" target="_blank"><i class="fab fa-instagram"></i></a>
                            <a href="https://www.youtube.com" target="_blank"><i class="fab fa-youtube"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 most">
                        <h4>Most Popular /</h4>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                                <a href="#"><p>Devops</p></a>
                                <a href="#"><p>Gadget</p></a>
                                <a href="#"><p>Bitcoin</p></a>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                                <a href="#"><p>International</p></a>
                                <a href="#"><p>Chatgpt</p></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-6 help">
                        <h4>Help /</h4>
                        <a href="#"><p>About</p></a>
                        <a href="#"><p>Media Kit</p></a>
                        <a href="#"><p>Advertise</p></a>
                        <a href="#"><p>Privacy Policy</p></a>
                        <a href="#"><p>Faq</p></a>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 newsletter">
                        <h4>Newsletter /</h4>
                        <p>Register now to get latest updates on promotion &amp; coupons.</p>
                        <div class="input-group mb-3 custom-input-group">
                            <span class="input-group-text" id="basic-addon1"><i class="far fa-envelope"></i></span>
                            <input type="text" class="form-control" placeholder="Email" aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                            <button type="button" class="btn">Subscribe -></button>
                    </div>
                    <hr>
                    <div class="copy">
                        <img src="/assets/images/logo-tech.svg">
                        <p class="pt-lg-4 pt-md-4 pt-sm-0 pt-0">© Copyright 2023 by Echo</p>
                    </div>
                </div>
                
  
            </div>
  
            </section>
              </template>
  
  <script>
  export default {
    name: 'SiteFooter',
  };
  </script>
  
  