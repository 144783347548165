<template>
    <div>
        <!-- info -->
        <section id="info">
            <div class="container-fluid" v-if="post">
                <div class="row text-center justify-content-center">
                    <div class="col-lg-12 banner">
                        <p class="text">ECHO &gt;  <span class="highlight"> {{ post.name }}</span></p>
                    </div>
                    <div class="col-lg-12 message">
                        <p>HOW CHATGPT CAN ASSIST IN CREATING MORE EFFECTIVE CONTENT</p>
                    </div>
                </div>
            </div>
        </section>


        <!-- main -->
        <section id="main">
            <div class="container-fluid pb-5" v-if="post">
                <div class="row">
                    <div class="col-lg-8 one">
                        <div class="image_top">
                            <img :src="post.image" alt="Post Image" class="post-image" width="100%">
                        </div>
                        <div class="head">
                            <h1>{{ post.name }}</h1>
                        </div>
                        <div class="metadata">
                            <span><a href="#"><i class="fas fa-user"></i> By Alexis</a></span>
                            <span><i class="fas fa-calendar"></i> Posted On {{ formattedDate }}</span>
                            <span><i class="fas fa-clock"></i> 2 minutes read</span>
                            <span><i class="fas fa-eye"></i> 116 Views</span>
                            <span><a href="#"><i class="fas fa-comments"></i> 0 Comments</a></span>
                        </div>

                            <div class="para" v-html="post.content"></div>


                        <!-- <div class="quote-box my-4">
                            <div class="quote-content">
                                <p><strong>“Life Imposes Things On You That You Can’t Control, But You Still Have The Choice Of How You’re Going To Live Through This.”</strong>
                                </p>
                                <span>- Celine Dion</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <img src="/assets/images/item-7-1.webp" alt="Image 1" width="100%"/>

                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12 pt-lg-0 pt-md-0 pt-sm-0 pt-3">
                                <img src="/assets/images/item-8-1.webp" alt="Image 2" width="100%"/>
                            </div>

                        </div>
                        <div class="head_1">
                            <h1>Better financial shape</h1>
                        </div>

                        <div class="para pb-3">
                            <p>Commodo viverra maecenas accumsan lacus vel facilisis. Auctor augue mauris augue neque. Ac
                                odio tempor orci dapibus ultrices in iaculis nunc sed. Elementum tempus egestas sed sed
                                risus. Id eu nisl nunc mi ipsum faucibus vitae. Sit amet nisl purus in mollis nunc sed id.
                                Sit amet justo donec enim diam. Amet porttitor eget dolor morbi non arcu risus quis. Mauris
                                vitae ultricies leo integer. Viverra accumsan in nisl nisi scelerisque eu. Pretium aenean
                                pharetra magna ac placerat vestibulum.</p>
                        </div>
                        <div class="row features my-lg-5 my-md-5 my-sm-5 my-0">
                            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                <p><i class="fas fa-check-circle"></i> Scelerisque varius morbi enim nunc</p>

                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                <p><i class="fas fa-check-circle"></i> Cursus metus aliquam eleifend nulla.</p>

                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                <p><i class="fas fa-check-circle"></i> Aliquam sem et tortor consequat</p>

                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                <p><i class="fas fa-check-circle"></i> Neque aenean euismod elementum.</p>

                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                <p><i class="fas fa-check-circle"></i> Vulputate sapien nec sagittis aliquam</p>

                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                <p><i class="fas fa-check-circle"></i> Ac tortor dignissim convallis aenean.</p>

                            </div>

                        </div>
                        <div class="para ">
                            <p>Mauris in aliquam sem fringilla ut morbi tincidunt augue. Odio ut sem nulla pharetra diam sit
                                amet nisl suscipit. Leo a diam sollicitudin tempor id eu nisl. Praesent semper feugiat nibh
                                sed pulvinar proin. Nisi quis eleifend quam adipiscing vitae proin. Odio euismod lacinia at
                                quis risus sed vulputate odio. Penatibus et magnis dis parturient montes nascetur ridiculus
                                mus.</p>
                        </div> -->

                        <div class="t_s my-4">
                            <div class="tags" v-if="post.tags && post.tags.length">
                                <h4 class="pt-2">Tags:</h4>
                                <a class="tag-item" v-for="tag in post.tags" :key="tag.id">{{ tag.name }}</a>
                            </div>
                            <div class="share">
                                <h4 class="pt-2">Share:</h4>
                                <a href="#" class="share-item"><i class="fab fa-facebook-f"></i></a>
                                <a href="#" class="share-item"><i class="fab fa-instagram"></i></a>
                                <a href="#" class="share-item"><i class="fab fa-pinterest-p"></i></a>
                                <a href="#" class="share-item"><i class="fas fa-times"></i></a>
                            </div>
                        </div>

                        <div class="alexis my-4">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                                    <img src="/assets/images/a6ece554da6d5395d6720406403e3951.png">
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9 col-12 info">
                                    <h5>Alexis</h5>
                                    <p>I am an Example Writer. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                                        do eiusmod tempor incididunt labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="might_like">
                            <h3>You Might Also Like</h3>
                            <div class="cards">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div class="article">
                                            <a href="#">
                                                <img src="/assets/images/bitcoin-new-116x108.webp" alt="Bitcoin Article">
                                            </a>
                                            <div class="article-content">
                                                <a href="#">
                                                    <h4>
                                                        Bitcoin Regulations – Navigating Legal Frontiers</h4>
                                                </a>
                                                <p><i class="fas fa-clock"></i> 2 minutes read</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-6 col-md-12 col-sm-12 col-12" v-if="post.categories && post.categories.length">
                                        <div class="article" v-for="category in post.categories" :key="category.id">
                                            <a href="#">
                                                <img src="/assets/images/bitcoin-new-116x108.webp" alt="Bitcoin Article">
                                            </a>
                                            <div class="article-content">
                                                <a href="#">
                                                    <h4>
                                                        {{ category.name }} {{ category.id }}</h4>
                                                </a>
                                                <p><i class="fas fa-clock"></i> 2 minutes read</p>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div class="article">
                                            <a href="#">
                                                <img src="/assets/images/bitcoin-new-116x108.webp" alt="Bitcoin Article">
                                            </a>
                                            <div class="article-content">
                                                <a href="#">
                                                    <h4>
                                                        Bitcoin Regulations – Navigating Legal Frontiers</h4>
                                                </a>
                                                <p><i class="fas fa-clock"></i> 2 minutes read</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="reply_form my-4">
                            <h2>Leave a Reply</h2>
                            <p>Your email address will not be published. Required fields are marked *</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" placeholder="Name*" required>
                                    <input type="email" placeholder="Email*" required>
                                </div>
                                <div class="form-group">
                                    <textarea placeholder="Comment" required></textarea>
                                </div>
                                <button type="submit" class="submit-btn">Post Comment</button>
                            </form>
                        </div>

                    </div>


                    <div class="col-lg-4 col-md-12 col-sm-12 col-12 two">

                        <div class="search">
                            <h3>Search</h3>
                            <div class="search-bar">
                                <input type="text" placeholder="Searching...">
                                <button type="submit"><i class="fas fa-search"></i></button>
                            </div>
                        </div>

                        <div class="top_story my-4">
                            <h3>Top Story</h3>
                            <div class="article">
                                <div class="row">
                                    <div class="col-lg-5 col-md-3 col-sm-3 col-12">
                                        <a href="#">
                                            <img src="/assets/images/bitcoin-new-116x108.webp" width="100%">
                                        </a>
        
                                    </div>
                                    <div class="col-lg-7 col-md-9 col-sm-9 col-12">
                                        <div class="article-content">
                                            <a href="#">
                                                <h4>Bitcoin Regulations – Navigating Legal Frontiers</h4>
                                            </a>
                                            <p><i class="fas fa-clock"></i> 2 minutes read</p>
                                        </div>
            
                                    </div>

                                </div>
                            </div>
                            <div class="article">
                                <div class="row">
                                    <div class="col-lg-5 col-md-3 col-sm-3 col-12">
                                        <a href="#">
                                            <img src="/assets/images/bitcoin-new-116x108.webp" width="100%">
                                        </a>
        
                                    </div>
                                    <div class="col-lg-7 col-md-9 col-sm-9 col-12">
                                        <div class="article-content">
                                            <a href="#">
                                                <h4>Bitcoin Regulations – Navigating Legal Frontiers</h4>
                                            </a>
                                            <p><i class="fas fa-clock"></i> 2 minutes read</p>
                                        </div>
            
                                    </div>

                                </div>
                            </div>
                            <div class="article">
                                <div class="row">
                                    <div class="col-lg-5 col-md-3 col-sm-3 col-12">
                                        <a href="#">
                                            <img src="/assets/images/bitcoin-new-116x108.webp" width="100%">
                                        </a>
        
                                    </div>
                                    <div class="col-lg-7 col-md-9 col-sm-9 col-12">
                                        <div class="article-content">
                                            <a href="#">
                                                <h4>Bitcoin Regulations – Navigating Legal Frontiers</h4>
                                            </a>
                                            <p><i class="fas fa-clock"></i> 2 minutes read</p>
                                        </div>
            
                                    </div>

                                </div>
                            </div>
                            <div class="article">
                                <div class="row">
                                    <div class="col-lg-5 col-md-3 col-sm-3 col-12">
                                        <a href="#">
                                            <img src="/assets/images/bitcoin-new-116x108.webp" width="100%">
                                        </a>
        
                                    </div>
                                    <div class="col-lg-7 col-md-9 col-sm-9 col-12">
                                        <div class="article-content">
                                            <a href="#">
                                                <h4>Bitcoin Regulations – Navigating Legal Frontiers</h4>
                                            </a>
                                            <p><i class="fas fa-clock"></i> 2 minutes read</p>
                                        </div>
            
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="div sticky-top">
                            <div class="second">
                                <h2>Follow Us</h2>
                                <div class="row butt">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <button type="button" class="btn btn-outline-secondary"><i class="fab fa-facebook"></i> 20K
                                            Fans</button>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <button type="button" class="btn btn-outline-secondary"><i class="fab fa-twitter"> </i> 6K
                                            Followers</button>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <button type="button" class="btn btn-outline-secondary"><i class="fab fa-instagram"> </i> 5K
                                            Followers</button>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <button type="button" class="btn btn-outline-secondary"><i class="fab fa-linkedin"> </i> 3K
                                            Followers</button>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <button type="button" class="btn btn-outline-secondary"><i class="fab fa-pinterest"> </i> 2K
                                            Followers</button>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                        <button type="button" class="btn btn-outline-secondary"><i class="fab fa-youtube"> </i> 4K
                                            Users</button>
                                    </div>
                                </div>
                            </div>
        
                            <div class="newsletter my-4">
                                <h2>Newsletter</h2>
                                <div class="newsletter-box">
                                    <p>Get Weekly Updates</p>
                                    <form>
                                        <div class="butt">
                                        <div class="input-group mb-3 custom-input-group">
                                            <span class="input-group-text" id="basic-addon1"><i class="far fa-envelope"></i></span>
                                            <input type="text" class="form-control" placeholder="Email" aria-label="Username"
                                                aria-describedby="basic-addon1">
                                        </div>
                                        
                                            <button type="submit" class="subscribe-btn">Subscribe</button>
                                        </div>
                                    
                                    </form>
                                </div>
                            </div>
                        </div>
                    

                    </div>
                </div>
            </div>

        </section>


     </div>
  </template>
  
  <script>
  import Vue from 'vue';
  import axios from 'axios';
  import VueAxios from 'vue-axios';
  
  Vue.use(VueAxios, axios);
  
  export default {
    name: 'BlogDetail',
    data() {
      return {
        post: null,
        formattedDate: '',
      };
    },
    mounted() {
      this.fetchPost(); // Fetch the post when the component mounts
    },
    methods: {
      formatDate(dateString) {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options); // Format as 'DD MMM YYYY'
      },
      async fetchPost() {
        const postSlug = this.$route.params.slug; // Use slug from the route
        try {
            const resp = await this.axios.get(`/posts/${postSlug}`);

        //   const resp = await axios.get(`http://127.0.0.1:8000/api/v1/posts/${postSlug}`);
          console.warn(resp.data); // Log the response for debugging
          this.post = resp.data.data;
          this.formattedDate = this.formatDate(this.post.created_at);
        } catch (error) {
          console.error('Error fetching post:', error); // Error handling
        }
      },
    },
  };
  </script>
    
  