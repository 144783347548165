<template>
    <div>
  

            <!-- section one -->
            <section id="one">
                <div class="container-fluid pt-5 pb-5">
                    <div class="row">
                        <div class="col-lg-7 col-md-12 col-sm-12 col-12 first_half">
                            <a href="#">
                                <div class="card">
                                    <img src="/assets/images/b1-2048x1536-1-870x632.webp">
                                    <div class="content">
                                        <span class="badge mx-4">Devops</span>
                                        <h2 class="mx-4">Implementing DevOps In Large Enterprises</h2>
                                        <div class="blog-meta post-like-comment-share mx-4">
                                            <div class="meta-item">
                                            <p><span>by</span> Alexis</p>
                                            </div>
                                            <div class="meta-item">
                                                <p><span>On</span> 19 Oct 2023 </p>
                                            </div>
                                        </div>
                                        <!-- <p class="mx-4"><span>by</span> Alexis &nbsp; &nbsp; &nbsp; <span>on</span> 19 Oct 2023
                                        </p> -->
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-5 col-md-12 col-sm-12 col-12 second_half pt-lg-0 pt-md-4 pt-3">
                            <a href="#">
                                <div class="card">
                                    <img src="/assets/images/chatbot-application-570x276.webp">
                                    <div class="content">
                                        <span class="badge mx-4">Chatgpt</span>
                                        <h2 class="mx-4">How ChatGPT Can Assist In Creating More Effective Content</h2>
                                        <div class="blog-meta post-like-comment-share mx-4">
                                            <div class="meta-item">
                                            <p><span>by</span> Alexis</p>
                                            </div>
                                            <div class="meta-item">
                                                <p><span>On</span> 19 Oct 2023 </p>
                                            </div>
                                        </div>
                                        <!-- <p class="mx-4"><span>by</span> Alexis &nbsp; &nbsp; &nbsp; <span>on</span> 19 Oct 2023
                                        </p> -->
                                    </div>
                                </div>
                            </a>
                            <a href="#">
                                <div class="card mt-4">
                                    <img src="/assets/images/ai-artificial-intelligence-technology-concept-570x276.webp">
                                    <div class="content">
                                        <span class="badge mx-4">Chatgpt</span>
                                        <h2 class="mx-4">Artificial Intelligence Technology Concept Uses</h2>
                                        <div class="blog-meta post-like-comment-share mx-4">
                                            <div class="meta-item">
                                            <p><span>by</span> Alexis</p>
                                            </div>
                                            <div class="meta-item">
                                                <p><span>On</span> 19 Oct 2023 </p>
                                            </div>
                                        </div>
  
                                        <!-- <p class="mx-4"><span>by</span> Alexis &nbsp; &nbsp; &nbsp; <span>on</span> 18 Oct 2023
                                        </p> -->
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section> 
  
            <!-- news -->
            <section id="news">
                <div class="container-fluid pt-5">
                <div class="row">
                    <div class="centered-text">
                    <div class="line"></div>
                    <h2 class="text">Trending News</h2>
                    <div class="line"></div>
                    </div>
                    <div class="col-lg-12">
                    <div class="home-demo">
  
                        <div class="owl-carousel owl-carousel-2 owl-theme mt-5">
                        <div class="item_2">
                            <img src="/assets/images/blockchain-370x300.jpg" />
                            <div class="content_2">
                            <a href="#" class="tag"><span class="badge">Bitcoin</span></a>
                            <h4 class="card-title">Bitcoin Regulations – Navigating Legal Frontiers</h4>
                            <p class="card-meta"><span>By</span> Alexis &nbsp;  &nbsp;   &nbsp;   <span>On</span> Oct 19, 2023</p>
                            </div>
                        </div>
                        <div class="item_2">
                            <img src="/assets/images/chat-370x300.webp" />
                            <div class="content_2">
                            <a href="#" class="tag"><span class="badge">Chatgpt</span></a>
                            <a href="#">
                                <h4 class="card-title">How ChatGPT Can Assist In Creating More Effective Content</h4>
                            </a>
                            <p class="card-meta"><span>By</span> Alexis &nbsp;  &nbsp;   &nbsp;   <span>On</span> Oct 19, 2023</p>
                            </div>
                        </div>
                        <div class="item_2">
                            <img src="/assets/images/blockchain-370x300.jpg" />
                            <div class="content_2">
                            <a href="#" class="tag"><span class="badge">Chatgpt</span></a>
                            <a href="#">
                                <h4 class="card-title">Artificial Intelligence Technology Concept Uses</h4>
                            </a>
  
                            <p class="card-meta"><span>By</span> Alexis &nbsp;  &nbsp;   &nbsp;   <span>On</span> Oct 19, 2023</p>
                            </div>
                        </div>
                        <div class="item_2">
                            <img src="/assets/images/chat-370x300.webp" />
                            <div class="content_2">
                            <a href="#" class="tag"><span class="badge">Chatgpt</span></a>
                            <a href="#">
                                <h4 class="card-title">Artificial Intelligence Technology Concept Uses</h4>
                            </a>
                            <p class="card-meta"><span>By</span> Alexis &nbsp;  &nbsp;   &nbsp;   <span>On</span> Oct 19, 2023</p>
                            </div>
                        </div>
                        <div class="item_2">
                            <img src="/assets/images/blockchain-370x300.jpg" />
                            <div class="content_2">
                            <a href="#" class="tag"><span class="badge">Chatgpt</span></a>
                            <a href="#">
                                <h4 class="card-title">Artificial Intelligence Technology Concept Uses</h4>
                            </a>
                            <p class="card-meta"><span>By</span> Alexis &nbsp;  &nbsp;   &nbsp;   <span>On</span> Oct 19, 2023</p>
                            </div>
                        </div>
  
  
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
  
  
            <!-- three -->
            <section id="three">
                <div class="container-fluid">
                    <div class="row ">
                        <div class="col-lg-8 first">
                            <div class="d-flex">
                                <h2>Devops</h2>
                                <p class="mt-3 line"></p>
                            </div>
                            
                            <div class="video mt-3">
                                <img src="/assets/images/blockchain-770x431.webp">
  
                                <p><span>by</span> Alexis&nbsp; &nbsp; <span>On</span> 24 Sep 2023</p>
  
                                <a href="#">A Journey Through The DevOps Pipeline</a>
                            </div>
                            <div class="row cards">
  
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="card">
                                        <img src="/assets/images/bitcoin-new-670x562.webp" class="card-img-top" alt="...">
                                        <div class="card-body p-0 mt-3">
                                            <p><span>On</span> 19 Oct 2023</p>
                                            <a href="#">
                                                <h6>Bitcoin Regulations – Navigating L</h6>
                                            </a>
                                        </div>
                                    </div>
                                </div>
  
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="card">
                                        <img src="/assets/images/b1-2048x1536-1-670x562.webp" class="card-img-top" alt="...">
                                        <div class="card-body p-0 mt-3">
                                            <p><span>On</span> 19 Oct 2023</p>
                                            <a href="#">
                                                <h6>Implementing DevOps In Large Enterprises</h6>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12 pt-lg-0 pt-md-3 pt-sm-3 pt-3 pb-lg-0 pb-md-3 pb-sm-3 pb-3">
                                    <div class="card">
                                        <img src="/assets/images/chatbot-application-670x562.webp" class="card-img-top"
                                            alt="...">
                                            <div class="card-body p-0 mt-3">
                                                <p><span>On</span> 19 Oct 2023</p>
                                            <a href="#">
                                                <h6>How ChatGPT Can Assist In Creating More</h6>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12 pt-lg-0 pt-md-3 pt-sm-3 pt-3 pb-lg-0 pb-md-3 pb-sm-3 pb-3">
                                    <div class="card">
                                        <img src="/assets/images/4-670x562.webp" class="card-img-top" alt="...">
                                        <div class="card-body p-0 mt-3">
                                            <p><span>On</span> 19 Oct 2023</p>
                                            <a href="#">
                                                <h6>World Happiness Report 2023: What’</h6>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
  
  
                        </div>
  
                        <div class="col-lg-4 second">
                            <div class="d-flex">
                                <h2>Follow us</h2>
                                <p class="mt-3 line"></p>
                            </div>
  
                            <div class="row butt mt-3">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                                    <button type="button" class="btn"><i class="fab fa-facebook"></i> 20K
                                        Fans</button>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                                    <button type="button" class="btn"><i class="fab fa-twitter"> </i> 6K
                                        Followers</button>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                                    <button type="button" class="btn"><i class="fab fa-instagram"> </i> 5K
                                        Followers</button>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                                    <button type="button" class="btn"><i class="fab fa-linkedin"> </i> 3K
                                        Followers</button>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                                    <button type="button" class="btn"><i class="fab fa-pinterest"> </i> 2K
                                        Followers</button>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                                    <button type="button" class="btn"><i class="fab fa-youtube"> </i> 4K
                                        Users</button>
                                </div>
                            </div>
  
                            <div class="popular">
                                <div class="d-flex">
                                    <h2>Popular Post</h2>
                                    <p class="mt-3 line"></p>
                                </div>
                                    <div class="row img_popular">
                                        <div class="col-lg-12 col-md-6">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <img src="/assets/images/headset-300x214.webp">
                                                </div>
                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <a href="#">
                                                        <h6>Gadget Galore: Exploring the Coolest Tech Innovations</h6>
                                                    </a>
                                                    <p>On Oct 18, 2023</p>
                                                </div>
                
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-6">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <img src="/assets/images/devopsmethod-300x214.webp">
                                                </div>
                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <a href="#">
                                                        <h6> Mastering Time Management for New Ideas</h6>
                                                    </a>
                                                    <p>On Oct 18, 2023</p>
                                                </div>
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-6">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <img src="/assets/images/full-300x214.webp">
                                                </div>
                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <a href="#">
                                                        <h6>Explore the Future with High-Tech Gadgets</h6>
                                                    </a>
                                                    <p>On Oct 18, 2023</p>
                                                </div>
                        
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-6">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <img src="/assets/images/iphone-battery-300x214.webp">
                                                </div>
                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <a href="#">
                                                        <h6>iPhone Tips for Prolonging Battery Life</h6>
                                                    </a>
                                                    <p>On Oct 18, 2023</p>
                                                </div>
                        
                                            </div>
                                        </div>
  
  
                                </div>
                            </div>
  
                        </div>
  
  
  
  
  
                    </div>
                </div>
            </section> 
  
            <!-- add -->
            <section id="add">
                <div class="container-fluid">
                    <div class="row text-center justify-content-center">
                        <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                            <img src="/assets/images/Group-947-1.webp" width="100%">
                        </div>
                    </div>
                </div>
            </section> 
  
            <!-- bitcoins -->
  
            <section id="bitcoin">
                <div class="container-fluid pb-4">
                    <div class="row">
                        <div class="centered-text">
                            <div class="line"></div>
                            <h2 class="text">Bitcoins</h2>
                            <div class="line"></div>
                        </div>
                        <div class="col-lg-7 col-md-6 col-sm-12 first">
                            <a href="#">
                                <div class="card">
                                    <img src="/assets/images/bitcoin-new-853x767.jpg" alt="DevOps">
                                    <div class="content">
                                        <span class="badge mx-4">Bitcoin</span>
                                        <h2 class="mx-4">Bitcoin Regulations – Navigating Legal Frontiers</h2>
                                        <p class="mx-4"><span>by</span> Alexis &nbsp; &nbsp; &nbsp; <span>on</span> 19 Oct 2023
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-12 col-12 second">
                            <div class="row text-center justify-content-center">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="card">
                                        <a href="#">
                                            <img src="/assets/images/b1-2048x1536-1-570x421.webp"
                                                alt="...">
                                        </a>
                                        <div class="card-body">
                                            <p class="card-text"><span>On</span> 19 Oct 2023</p>
                                            <a class="head" href="#">
                                                Implementing DevOps In Large Enterprises
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="card">
                                        <a href="#">
                                            <img src="/assets/images/chatbot-application-570x421.webp" class="card-img-top"
                                                alt="...">
                                        </a>
                                        <div class="card-body">
                                            <p class="card-text"><span>On</span> 19 Oct 2023</p>
                                            <a class="head" href="#">
                                                How ChatGPT Can Assist In Creating More Effective Content
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="card">
                                        <a href="#">
                                            <img src="/assets/images/4-570x421.webp" class="card-img-top" alt="...">
                                        </a>
                                        <div class="card-body">
                                            <p class="card-text"><span>On</span> 19 Oct 2023</p>
                                            <a class="head" href="#">
                                                World Happiness Report 2023: What’s The Devops?
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="card">
                                        <a href="#">
                                            <img src="/assets/images/smart-home-570x421.webp" class="card-img-top" alt="...">
                                        </a>
                                        <div class="card-body">
                                            <p class="card-text"><span>On</span> 19 Oct 2023</p>
                                            <a class="head" href="#">
                                                Smart Home Gadgets For A Futuristic Lifestyle
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
  
                        </div>
                    </div>
                </div>
            </section>
  
            <!-- slider -->
            <section id='slider'>
                <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                    <div class="home-demo">
                        <div class="owl-carousel owl-theme">
                        <div class="item">
                            <img src="/assets/images/item-10-1.webp" />
                            <div class="content">
                            <a href="#" class="title">Gadget</a>
                            <div class="subtitle">4+ Articles</div>
                            </div>
                        </div>
                        <div class="item">
                            <img src="/assets/images/item-12.webp" />
                            <div class="content">
                            <a href="#" class="title">Bitcoin</a>
                            <div class="subtitle">4+ Articles</div>
                            </div>
                        </div>
                        <div class="item">
                            <img src="/assets/images/item-13.webp" />
                            <div class="content">
                            <a href="#" class="title">Devops</a>
                            <div class="subtitle">4+ Articles</div>
                            </div>
                        </div>
                        <div class="item">
                            <img src="/assets/images/item-10-1.webp" />
                            <div class="content">
                            <a href="#" class="title">Chatgpt</a>
                            <div class="subtitle">4+ Articles</div>
                            </div>
                        </div>
                        <div class="item">
                            <img src="/assets/images/item-12.webp" />
                            <div class="content">
                            <a href="#" class="title">Gadget</a>
                            <div class="subtitle">4+ Articles</div>
                            </div>
                        </div>
                        <div class="item">
                            <img src="/assets/images/item-10-1.webp" />
                            <div class="content">
                            <a href="#" class="title">Devops</a>
                            <div class="subtitle">4+ Articles</div>
                            </div>
                        </div>
                        <div class="item">
                            <img src="/assets/images/item-13.webp" />
                            <div class="content">
                            <a href="#" class="title">Chatgpt</a>
                            <div class="subtitle">4+ Articles</div>
                            </div>
                        </div>
  
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
  
            <!-- chatgpt -->
  
            <section id='chatgpt'>
            <div class="container-fluid">
                <div class="row">
                <div class="col-lg-12 intro">
                    <h1>ChatGPT</h1>
                    <hr class="line" />
                </div>
                <div class="row main">
                    <div class="col-lg-8 col-md-6 col-sm-12 col-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 one">
                        <div class="card">
                            <img src="/assets/images/b1-2048x1536-1-839x622.webp" alt="DevOps" width="100%"/>
                            <div class="content">
                            <div class="meta">
                                <a href="#">
                                <span>By</span> Alexis
                                </a>
                                <p class="mx-3"><span>On</span> 19 Oct 2023</p>
                            </div>
                            <h2>Implementing DevOps In Large Enterprises</h2>
                            <p>Mauris in aliquam sem fringilla ut morbi tincidunt augue. Odio ut sem nulla pharetra diam sit amet nisl suscipit. Leo a diam...</p>
                            </div>
                        </div>
                        </div>
  
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 two">
                        <div class="card">
                            <img src="/assets/images/b1-2048x1536-1-839x622.webp" />
                            <div class="content">
                            <div class="meta">
                                <a href="#">
                                <span>By</span> Alexis
                                </a>
                                <p class="mx-3"><span>On</span> 19 Oct 2023</p>
                            </div>
                            <h2>Implementing DevOps In Large Enterprises</h2>
                            <p>Mauris in aliquam sem fringilla ut morbi tincidunt augue. Odio ut sem nulla pharetra diam sit amet nisl suscipit. Leo a diam...</p>
                            </div>
                        </div>
                        </div>
  
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 second_card">
                        <div class="row">
                            <div class="col-lg-4 one_card">
                            <img src="/assets/images/chat-109x109.webp" width="100%"/>
                            </div>
                            <div class="col-lg-8 one_card_info">
                            <h4>Greening The Environmental Impact of ChatGpt</h4>
                            <p><span>On</span> Sep 24, 2023</p>
                            </div>
  
                            <div class="col-lg-4 one_card">
                            <img src="/assets/images/chat-109x109.webp" width="100%"/>
                            </div>
                            <div class="col-lg-8 one_card_info">
                            <h4>Unlocking Efficiencies With Accounting Software</h4>
                            <p><span>On</span> Sep 24, 2023</p>
                            </div>
  
                            <div class="col-lg-4 one_card">
                            <img src="/assets/images/chat-109x109.webp" width="100%"/>
                            </div>
                            <div class="col-lg-8 one_card_info">
                            <h4>Unlocking Efficiencies With Accounting Software</h4>
                            <p><span>On</span> Sep 24, 2023</p>
                            </div>
  
                        </div>
                        </div>
  
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 third_card">
                        <div class="row">
                            <div class="col-lg-4 two_card">
                            <img src="/assets/images/chat-109x109.webp" width="100%"/>
                            </div>
                            <div class="col-lg-8 two_card_info">
                            <h4>Unlocking Efficiencies With Accounting Software</h4>
                            <p><span>On</span> Sep 24, 2023</p>
                            </div>
                            <div class="col-lg-4 two_card">
                            <img src="/assets/images/chat-109x109.webp" width="100%"/>
                            </div>
                            <div class="col-lg-8 two_card_info">
                            <h4>Unlocking Efficiencies With Accounting Software</h4>
                            <p><span>On</span> Sep 24, 2023</p>
                            </div>
                            <div class="col-lg-4 two_card">
                            <img src="/assets/images/chat-109x109.webp" width="100%"/>
                            </div>
                            <div class="col-lg-8 two_card_info">
                            <h4>Unlocking Efficiencies With Accounting Software</h4>
                            <p><span>On</span> Sep 24, 2023</p>
                            </div>
  
                        </div>
                        </div>
  
                    </div>
                    </div>
  
  
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 three">
                    <div class="d-flex">
                        <h1>Featured Post</h1>
                        <p class="mt-3 line"></p>
                    </div>
  
                    <div class="f_p">
                        <a href="#">
                        <div class="card">
                            <img src="/assets/images/blockchain-870x632.webp" alt="Blockchain" />
                            <div class="content">
                            <h2 class="mx-4">A Journey Through The DevOps Pipeline</h2>
                            <p class="mx-4"><span>by</span> Alexis &nbsp; &nbsp; &nbsp; <span>on</span> 24 Sep 2023</p>
                            </div>
                        </div>
                        </a>
                    </div>
  
                    <div class="newsletter-box my-4">
                        <p>Get Weekly Updates</p>
                        <form>
                        <div class="butt">
                            <div class="input-group mb-3 custom-input-group">
                            <span class="input-group-text" id="basic-addon1">
                                <i class="fas fa-envelope"></i>
                            </span>
                            <input type="text" class="form-control" placeholder="Email" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>
                            <button type="submit" class="subscribe-btn">Subscribe</button>
                        </div>
                        </form>
                    </div>
                    <div class="add_image">
                        <img src="/assets/images/add-banner.webp" alt="Advertisement" width="100%"/>
                    </div>
                    </div>
  
                </div>
                </div>
            </div>
            </section>

            
            <!-- Don't Miss This -->
            <section id="miss">
                <div class="container-fluid">
                <div class="row">
                    <div class="centered-text">
                    <div class="line"></div>
                    <h2 class="text">Don't Miss This</h2>
                    <div class="line"></div>
                    </div>
                    <!-- Loop through filtered list of posts -->
                    <div class="col-lg-3 col-md-6 col-sm-12 col-12" v-for="item in list" :key="item.id">
                    <div class="card" style="height: 400px;">
                        <img :src="item.image" alt="Post Image" class="post-image" width="100%" height="174px">
                        <div class="card-body p-0">
                        <p class="pt-3">
                            BY <span v-for="category in item.categories" :key="category.id">{{ category.name }}</span>
                            &nbsp; ON <span>{{ item.formattedDate }}</span>
                        </p>
                        <router-link :to="`/${item.slug}`">
                            <h1>{{ item.name }}</h1>
                        </router-link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
  
            <!-- 3sec -->
            <section id="three_sec">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="devops">
                                <h3>Devops</h3>
                                <p class="line mt-3"></p>
  
                                <div class="news-item">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 col-sm-2 col-2">
                                            <img src="/assets/images/b1-2048x1536-1-100x105.webp" alt="Image" width="100%" />
                                        </div>
                                        <div class="col-lg-8 col-md-10 col-sm-10 col-10">
                                            <div class="info">
                                                <a href="#">Implementing DevOps in Large Enterprises</a>
                                                <p><span>On</span> Oct 19, 2023</p>
                                            </div>
                
                                        </div>
                                    </div>
                                </div>
                                <div class="news-item">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 col-sm-2 col-2">
                                            <img src="/assets/images/iphone-battery-100x105.webp" alt="Image" width="100%" />
                                        </div>
                                        <div class="col-lg-8 col-md-10 col-sm-10 col-10">
                                            <div class="info">
                                                <a href="#">World Happiness Report 2023: What’s the Devops?</a>
                                                <p><span>On</span> Oct 19, 2023</p>
                                            </div>
                        
                                        </div>
                                    </div>
                                </div>
                                <div class="news-item">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 col-sm-2 col-2">
                                            <img src="/assets/images/b1-2048x1536-1-100x105.webp" alt="Image" width="100%" />
  
                                        </div>
                                        <div class="col-lg-8 col-md-10 col-sm-10 col-10">
                                            <div class="info">
                                                <a href="#">Implementing DevOps in Large Enterprises</a>
                                                <p><span>On</span> Oct 19, 2023</p>
                                            </div>
                        
                                        </div>
                                    </div>
  
                                </div>
                                <div class="news-item">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 col-sm-2 col-2">
                                            <img src="/assets/images/iphone-battery-100x105.webp" alt="Image" width="100%" />
  
                                        </div>
                                        <div class="col-lg-8 col-md-10 col-sm-10 col-10">
                                            <div class="info">
                                                <a href="#">World Happiness Report 2023: What’s the Devops?</a>
                                                <p><span>On</span> Oct 19, 2023</p>
                                            </div>
                    
                                        </div>
                                    </div>
  
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="gadget">
                                <h3>Gadget</h3>
                                <p class="line mt-3"></p>
                                <div class="news-item">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 col-sm-2 col-2">
                                            <img src="/assets/images/iphone-battery-100x105.webp" alt="Image" width="100%" />
  
                                        </div>
                                        <div class="col-lg-8 col-md-10 col-sm-10 col-10">
                                            <div class="info">
                                                <a href="#">World Happiness Report 2023: What’s the Devops?</a>
                                                <p><span>On</span> Oct 19, 2023</p>
                                            </div>
                    
                                        </div>
                                    </div>
  
                                </div>
                                <div class="news-item">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 col-sm-2 col-2">
                                            <img src="/assets/images/b1-2048x1536-1-100x105.webp" alt="Image" width="100%" />
  
                                        </div>
                                        <div class="col-lg-8 col-md-10 col-sm-10 col-10">
                                            <div class="info">
                                                <a href="#">Smart Home Gadgets for a Futuristic Lifestyle</a>
                                                <p><span>On</span> Oct 19, 2023</p>
                                            </div>
                        
                                        </div>
                                    </div>
  
                                </div>
                                <div class="news-item">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 col-sm-2 col-2">
                                            <img src="/assets/images/iphone-battery-100x105.webp" alt="Image" width="100%" />
  
                                        </div>
                                        <div class="col-lg-8 col-md-10 col-sm-10 col-10">
                                            <div class="info">
                                                <a href="#">World Happiness Report 2023: What’s the Devops?</a>
                                                <p><span>On</span> Oct 19, 2023</p>
                                            </div>
                        
                                        </div>
                                    </div>
  
                                </div>
                                <div class="news-item">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 col-sm-2 col-2">
                                            <img src="/assets/images/b1-2048x1536-1-100x105.webp" alt="Image" width="100%" />
  
                                        </div>
                                        <div class="col-lg-8 col-md-10 col-sm-10 col-10">
                                            <div class="info">
                                                <a href="#">Smart Home Gadgets for a Futuristic Lifestyle</a>
                                                <p><span>On</span> Oct 19, 2023</p>
                                            </div>
                    
                                        </div>
                                    </div>
  
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="chatgpt">
                                <h3>Chatgpt</h3>
                                <p class="line mt-3"></p>
                                <div class="news-item">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 col-sm-2 col-2">
                                            <img src="/assets/images/iphone-battery-100x105.webp" alt="Image" width="100%" />
  
                                        </div>
                                        <div class="col-lg-8 col-md-10 col-sm-10 col-10">
                                            <div class="info">
                                                <a href="#">World Happiness Report 2023: What’s the Devops?</a>
                                                <p><span>On</span> Oct 19, 2023</p>
                                            </div>
                    
                                        </div>
                                    </div>
  
                                </div>
                                <div class="news-item">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 col-sm-2 col-2">
                                            <img src="/assets/images/b1-2048x1536-1-100x105.webp" alt="Image" width="100%" />
  
                                        </div>
                                        <div class="col-lg-8 col-md-10 col-sm-10 col-10">
                                            <div class="info">
                                                <a href="#">How ChatGPT can assist in creating content</a>
                                                <p><span>On</span> Oct 19, 2023</p>
                                            </div>
                        
                                        </div>
                                    </div>
  
                                </div>
                                <div class="news-item">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 col-sm-2 col-2">
                                            <img src="/assets/images/iphone-battery-100x105.webp" alt="Image" width="100%" />
  
                                        </div>
                                        <div class="col-lg-8 col-md-10 col-sm-10 col-10">
                                            <div class="info">
                                                <a href="#">World Happiness Report 2023: What’s the Devops?</a>
                                                <p><span>On</span> Oct 19, 2023</p>
                                            </div>
                        
                                        </div>
                                    </div>
  
                                </div>
                                <div class="news-item">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 col-sm-2 col-2">
                                            <img src="/assets/images/b1-2048x1536-1-100x105.webp" alt="Image" width="100%" />
                                        </div>
                                        <div class="col-lg-8 col-md-10 col-sm-10 col-10">
                                            <div class="info">
                                                <a href="#">How ChatGPT can assist in creating content</a>
                                                <p><span>On</span> Oct 19, 2023</p>
                                            </div>
                    
                                        </div>
                                    </div>
  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>   
  
  
            <!-- footer -->

  
  
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  import axios from 'axios';
  import VueAxios from 'vue-axios';
  
  Vue.use(VueAxios, axios);
  
  export default {
    name: 'HomePage',
    data() {
      return {
        list: [] // Initialize as an empty array
      };
    },
    mounted() {
      this.fetchPosts(); // Fetch posts when component mounts
    },
    metaInfo() {
      return {
        title: 'Home Page -  Blog',
        meta: [
          {
            name: 'description',
            content: 'Explore the latest articles and updates on , DevOps, and more on our blog.'
          },
          {
            property: 'og:title',
            content: 'Home Page -  Blog'
          },
          {
            property: 'og:description',
            content: 'Explore the latest articles and updates on , DevOps, and more on our blog.'
          },
          {
            property: 'og:image',
            content: '/assets/images/og-image.webp' // Replace with your Open Graph image
          },
          {
            property: 'og:url',
            content: window.location.href
          }
        ]
      };
    },
    methods: {
      formatDate(dateString) {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options); // Format as 'DD MMM YYYY'
      },
      async fetchPosts() {
        try {
          const resp = await this.axios.get('/posts');
          console.warn(resp.data); // Log the response for debugging
  
          // Filter posts that have category_id 10 in their categories and limit to 8
          this.list = resp.data.data
            .filter(post => post.categories.some(category => category.id == 10)) // Filter by category_id 10
            .map(post => ({
              ...post,
              formattedDate: this.formatDate(post.created_at) // Add formatted date
            }))
            .slice(0, 8); // Limit to the first 8 posts
        } catch (error) {
          console.error('Error fetching posts:', error); // Error handling
        }
      }
    }
  };
  </script>